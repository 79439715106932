html {
    height: 100%;
}

body {
    height: 100%;
    overflow: hidden;
}

#root {
    font-family: sans-serif;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.CodeMirror, .CodeMirror-scroll {
    min-height: 0;
}

.CodeMirror {
    flex: auto;
}

blockquote {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 50px;
    padding-left: 15px;
    border-left: 3px solid #ccc;
}

.blinking {
    animation: helgoboss-blink 0.1s step-start 0s infinite;
}

.abcjs-inner {
    overflow: auto hidden !important;
}

@keyframes helgoboss-blink {
    50% {
        opacity: 0.0;
    }
}

@keyframes pulse_animation {
    0% { transform: scale(1); }
    30% { transform: scale(1); }
    40% { transform: scale(1.08); }
    50% { transform: scale(1); }
    60% { transform: scale(1); }
    70% { transform: scale(1.05); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
}

.pulse {
    animation-name: pulse_animation;
    animation-duration: 2000ms;
    transform-origin:70% 70%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

/* For making fullscreen sheet scrollable */
.fullscreen #sheet {
    height: 100%;
}